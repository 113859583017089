var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", { staticClass: "bg-white" }, [
    _c("div", { staticClass: "container xs:d-none" }, [
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          {
            staticClass: "xs:col-12 sm:col-6 md-up:col-3 py-4",
            staticStyle: { "line-height": "1" }
          },
          [
            _c("h4", { staticClass: "mb-0 bold xs:d-inline" }, [
              _vm._v("Comments")
            ]),
            _vm._v(" "),
            _c(
              "span",
              {
                staticClass: "h3 font-light text-primary",
                class: { "text-hide": _vm.comments === undefined },
                staticStyle: { transition: ".3s" }
              },
              [_vm._v(_vm._s(_vm.comments))]
            )
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "xs:col-12 sm:col-6 md-up:col-3 py-4",
            staticStyle: { "line-height": "1" }
          },
          [
            _c("h4", { staticClass: "mb-0 bold xs:d-inline" }, [
              _vm._v("Views")
            ]),
            _vm._v(" "),
            _c(
              "span",
              {
                staticClass: "h3 font-light text-primary",
                class: { "text-hide": _vm.views === undefined },
                staticStyle: { transition: ".3s" }
              },
              [_vm._v(_vm._s(_vm.views))]
            )
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "xs:col-12 sm:col-6 md-up:col-3 py-4",
            staticStyle: { "line-height": "1" }
          },
          [
            _c("h4", { staticClass: "mb-0 bold xs:d-inline" }, [
              _vm._v("Likes")
            ]),
            _vm._v(" "),
            _c(
              "span",
              {
                staticClass: "h3 font-light text-primary",
                class: { "text-hide": _vm.likes === undefined },
                staticStyle: { transition: ".3s" }
              },
              [_vm._v(_vm._s(_vm.likes))]
            )
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "xs:col-12 sm:col-6 md-up:col-3 py-4",
            staticStyle: { "line-height": "1" }
          },
          [
            _c("h4", { staticClass: "mb-0 bold xs:d-inline" }, [
              _vm._v("Dislikes")
            ]),
            _vm._v(" "),
            _c(
              "span",
              {
                staticClass: "h3 font-light text-primary",
                class: { "text-hide": _vm.dislikes === undefined },
                staticStyle: { transition: ".3s" }
              },
              [_vm._v(_vm._s(_vm.dislikes))]
            )
          ]
        )
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "container py-3 sm-up:d-none" }, [
      _c("nav", { staticClass: "nav flex-column" }, [
        _c("span", { staticClass: "btn btn-white text-left w-100" }, [
          _c("i", { staticClass: "i-message-square float-left" }),
          _vm._v(" "),
          _c("span", [_vm._v("Comments - " + _vm._s(_vm.comments))])
        ]),
        _vm._v(" "),
        _c("span", { staticClass: "btn btn-white text-left w-100" }, [
          _c("i", { staticClass: "i-eye float-left" }),
          _vm._v(" "),
          _c("span", [_vm._v("Views - " + _vm._s(_vm.views))])
        ]),
        _vm._v(" "),
        _c("span", { staticClass: "btn btn-white text-left w-100" }, [
          _c("i", { staticClass: "i-thumbs-up float-left" }),
          _vm._v(" "),
          _c("span", [_vm._v("Likes - " + _vm._s(_vm.likes))])
        ]),
        _vm._v(" "),
        _c("span", { staticClass: "btn btn-white text-left w-100" }, [
          _c("i", { staticClass: "i-thumbs-down float-left" }),
          _vm._v(" "),
          _c("span", [_vm._v("Dislikes - " + _vm._s(_vm.dislikes))])
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }